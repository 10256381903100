<!--
 * @Author: wch
 * @Date: 2020-07-30 20:07:57
 * @LastEditTime: 2020-08-26 18:58:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\equipmentState\berthStateStatistics\faultPlate.vue
-->
<template>
  <div id="faultPlate" class="faultPlate"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dataList: {
        yData: [],
        seriesData: [],
      },
    };
  },
  props: {
    faultPlate: {
      type: Array,
      default: function () {
        return [];
      },
    },
    unit: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  watch: {
    faultPlate: {
      handler(value) {
        this.dataList = {
          yData: [],
          seriesData: [],
        };
        for (let i = 0; i < value.length; i++) {
          this.dataList.yData.push(value[i].parkName);
          this.dataList.seriesData.push(value[i].count);
        }
        this.drawGraph();
      },
      deep: true,
    },
  },
  // 方法集合
  methods: {
    drawGraph() {
      let parkName = this.$route.path === '/berthStateTotal' ? '故障次数' : '故障泊位'
      // console.log(parkName);
      let that = this;
      let faultPlate = this.$echarts.init(
        document.getElementById("faultPlate")
      );
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return ` <p>停车场：${params[0].name}</p>
              <p>${parkName}：${params[0].value}${
              that.unit ? that.unit : "次"
            }</p> `;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: that.dataList.yData.reverse(),
          axisTick: {
            alignWithLabel: true,
          },
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 10,
        //   top: 40,
        //   data: ['空闲泊位数', '占用泊位数']
        // },
        series: [
          {
            type: "bar",
            data: that.dataList.seriesData.reverse(),
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    "#36CFC9",
                    "#40A9FF",
                    "#597EF7",
                    "#9254DE",
                    "#F759AB",
                    "#FFA940",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      faultPlate.setOption(option, true);
    },
  },
  created() {},
  mounted() {
    this.drawGraph();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.faultPlate
  width: 100%;
  height: 300px;
  overflow: hidden;
</style>
