<!--
 * @Author: 王昌浩
 * @Date: 2020-07-30 18:25:49
 * @LastEditTime: 2020-08-19 17:15:11
 * @LastEditors: Please set LastEditors
 * @Description: 当前泊位状态占比
 * @FilePath: \trunk\src\pages\equipmentState\berthStateStatistics\berthState.vue
-->
<template>
  <div :id="type" class="circularChart"></div>
</template>
<script>
export default {
  props: {
    type: {
      default: "c1",
    },
    berthOccupy: {
      type: Array,
      default: function () {
        return [];
      },
    },
    memberCarNumber: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      berthList: [],
      memberCarNumberList: [],
      tipTitle: "",
    };
  },
  watch: {
    berthOccupy: {
      handler(value) {
        this.berthList = [];
        let berthList = value;
        for (let i = 0; i < berthList.length; i++) {
          this.berthList.push({
            name: berthList[i].status == 0 ? "异常" : "正常",
            value: berthList[i].count,
          });
        }
        this.drawGraph();
      },
      deep: true,
    },
    memberCarNumber: {
      handler(value) {
        this.memberCarNumberList = [];
        let memberCarNumberList = value;
        for (let i = 0; i < memberCarNumberList.length; i++) {
          this.memberCarNumberList.push({
            name: memberCarNumberList[i].desc,
            value: memberCarNumberList[i].count,
          });
        }
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(
        document.getElementById(this.type)
      );
      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `
              <p>
                ${that.type == "c1" ? "状态：" : "故障类型："}
                ${params.data.name}
              </p>
              <p>
                ${that.type == "c1" ? "数量：" : "次数："}
                ${params.data.value}
                ${that.type == "c1" ? "个" : "次"}
              </p>
              <p>占比：${params.percent}%</p>
            `;
          },
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 10,
        //   top: 40,
        //   data: ['空闲泊位数', '占用泊位数']
        // },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            data: that.type == "c1" ? that.berthList : that.memberCarNumberList,
          },
        ],
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width: 100%;
  height: 300px;
  overflow: hidden;
</style>
